import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthContextProvider } from './context/authContext';

import "./assets/css/bootstrap.min.css";
import "./assets/css/theme.css";
import "./assets/css/style.css";

ReactDOM.render(
  <AuthContextProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </AuthContextProvider>,
  document.getElementById("root")  
);