import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useCreateTransaction } from '../hooks/useCreateTransaction';
import { useLogin } from '../hooks/useLogin';

const Users = () => {

	const { admin } = useAuthContext();

	const [userData, setUserData] = useState();
	const fetchUsers = async () => {
		const response = await fetch('https://api.ridechain.io/user', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
		}
	}

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<>
			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Manage Users</h3>
						<p className="mt-2 mb-0">Total {userData && userData.length} Users</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">钱包</p></th>
									<th className="p-3" scope="col"><p className="mb-0">挖矿/业绩</p></th>
									<th className="p-3" scope="col"><p className="mb-0">收益</p></th>
									<th className="p-3" scope="col"><p className="mb-0">钱包</p></th>
								</tr>
							</thead>
							<tbody>
								{userData && userData.map((user, index) => (
									<tr key={index}>

										<td className="px-3 py-3">
											<p className="mb-0">
												<strong>等级：</strong>
												{user.rank == 0 ? "无等级" : <></>}
												{user.rank == 1 ? "Miner" : <></>}
												{user.rank == 2 ? "P1" : <></>}
												{user.rank == 3 ? "P2" : <></>}
												{user.rank == 4 ? "P3" : <></>}
												{user.rank == 5 ? "P4" : <></>}
												{user.rank == 6 ? "P5" : <></>}
												{user.rank == 7 ? "P6" : <></>}
											</p>
											<p className="mb-0">{user.wallet}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>个人配套：</strong>{parseFloat(user.total_deposit).toFixed(2)}</p>
											<p className="mb-0"><strong>团队业绩：</strong>{parseFloat(user.total_team_sales).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>静态：</strong>{parseFloat(user.mining_profit).toFixed(2)}</p>
											<p className="mb-0"><strong>动态：</strong>{parseFloat(user.rankBonus).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>RC：</strong>{parseFloat(user.rc).toFixed(2)}</p>
											<p className="mb-0"><strong>RCT：</strong>{parseFloat(user.rct).toFixed(2)}</p>
											<p className="mb-0"><strong>SRC：</strong>{parseFloat(user.src).toFixed(2)}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  	);

};

export default Users;