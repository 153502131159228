import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useCreateTransaction } from '../hooks/useCreateTransaction';
import { useLogin } from '../hooks/useLogin';

const Withdrawal = () => {

	const { admin } = useAuthContext();

	// Set state for blockchain data
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);

	const [copied, setCopied] = useState(false);
	const [copyText, setCopyText] = useState("");
	const copyAddress = () => {    
	  setCopied(true);
	  setCopyText("Copied.");
	  setTimeout(() => setCopied(false), 3000);
	}

	const [withdrawalData, setWithdrawalData] = useState();
	const [totalWithdrawals, setTotalWithdrawals] = useState(0);
	const [totalCompletedWithdrawals, setTotalCompletedWithdrawals] = useState(0);
	const [withdrawalsByDay, setWithdrawalsByDay] = useState({});

	const fetchAllWithdrawals = async () => {
		const response = await fetch('https://api.ridechain.io/withdrawal');
		const json = await response.json();
	  
		if (response.ok) {
		  console.log("Withdrawals:", json);
		  setWithdrawalData(json);
	  
		  // Calculate the total withdrawal amount for all records excluding "Cancelled"
		  const total = json
			.filter(withdrawal => withdrawal.status !== "Cancelled")
			.reduce((acc, withdrawal) => acc + parseFloat(withdrawal.usdt_amount), 0);
		  setTotalWithdrawals(total);
	  
		  // Calculate the total withdrawal amount for "Completed" status
		  const totalCompleted = json
			.filter(withdrawal => withdrawal.status === "Completed")
			.reduce((acc, withdrawal) => acc + parseFloat(withdrawal.usdt_amount), 0);
		  setTotalCompletedWithdrawals(totalCompleted);
	  
		  // Group withdrawals by day (using GMT+8) and calculate totals for each day
		  const groupedByDay = json.reduce((acc, withdrawal) => {
			// Skip "Cancelled" withdrawals from all calculations
			if (withdrawal.status === "Cancelled") return acc;
	  
			const day = Moment(withdrawal.createdAt).utcOffset(8).format('YYYY-MM-DD');
			if (!acc[day]) {
			  acc[day] = {
				withdrawals: [],
				totalAmount: 0,
				totalCompletedAmount: 0
			  };
			}
	  
			acc[day].withdrawals.push(withdrawal);
			acc[day].totalAmount += parseFloat(withdrawal.usdt_amount);
	  
			if (withdrawal.status === "Completed") {
			  acc[day].totalCompletedAmount += parseFloat(withdrawal.usdt_amount);
			}
	  
			return acc;
		  }, {});
	  
		  setWithdrawalsByDay(groupedByDay);
		}
	  };
	  

	const [withdrawing, setWithdrawing] = useState(false);
	const [withdrawSuccess, setWithdrawSuccess] = useState(false);

	const distributeWithdraw = (_data) => {
		setWithdrawing(true);
		blockchain.usdtContract.methods
		.transfer(_data.wallet, blockchain.web3.utils.toWei((_data.usdt_amount).toString()))
		.send({
		  gasLimit: 75000,
		  maxPriorityFeePerGas: 3000000000,
		  maxFeePerGas: 3000000000,
		  from: blockchain.account,
		  value: 0,
		})
		// Transaction fail
		.once("error", (err) => {
		  setWithdrawing(false);
		})
		// Transaction success
		.then((receipt) => {
			console.log("Transaction Success:", receipt);
			fetch('https://api.ridechain.io/withdrawal/'+_data._id, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${admin.token}`
				},
				body: JSON.stringify({
					status: "Completed",
					txn_link: "https://bscscan.com/tx/"+receipt.transactionHash
				})
		  	});
		  	setWithdrawSuccess(true);
		  	setTimeout(() => setWithdrawing(false), 2000);
			setTimeout(() => fetchAllWithdrawals(), 3000);
		});
	};

	const getData = () => {
		if (blockchain.account !== "" && blockchain.usdtContract !== null) {
			console.log("Connect Wallet");
		}
	};

	useEffect(() => {
		getData();
		fetchAllWithdrawals();
	}, [blockchain.account]);

	return (
		<>
			<div className="container-fluid fluid-padding">
				<div className="row my-3">
				<div className="col-12 my-3">
						<h3 className="">Withdrawals</h3>
						<p className=" mt-2 mb-0" style={{fontSize:"14px"}}>Total Withdrawals: {parseFloat(totalWithdrawals).toFixed(2)} USDT</p>
						<p className=" mt-2 mb-0" style={{fontSize:"14px"}}>Pending Withdrawals: <span className="yellow">{parseFloat(totalWithdrawals-totalCompletedWithdrawals).toFixed(2)} USDT</span></p>
						{blockchain.account === "" || blockchain.usdtContract === null ? 
							<button className="btn p-1 mt-2 btn-primary" onClick={(e) => {
								e.preventDefault();
								dispatch(connect());
								getData();
							}}>
								<p className="mb-0" style={{fontSize:"12px"}}>Connect</p>
							</button>
							:
							<p className="mt-2 mb-0" style={{fontSize:"14px"}}>Wallet Connected: <br></br>{blockchain.account}</p>
						}
					</div>
					<div className="col-12">
						{Object.keys(withdrawalsByDay).map(day => (
							<div key={day}>
								<div className="data-box mt-3">
									<h5 className="blue">{day}</h5>
									<p className="mb-0">Total Withdrawals: {withdrawalsByDay[day].totalAmount.toFixed(2)} USDT</p>
									<p className="mb-0">Pending Withdrawals: <span className="yellow">{parseFloat(withdrawalsByDay[day].totalAmount-withdrawalsByDay[day].totalCompletedAmount).toFixed(2)}</span> USDT</p>
									<hr className="divider"></hr>
									{withdrawalsByDay[day].withdrawals.map((withdrawal, index) => (
										<>
										{withdrawal.status == "Processing" ? (
											<div className="mb-3" key={index}>
												<p className="mb-0" style={{fontSize:"12px"}}>{withdrawal._id}</p>
												<p className="mb-0" style={{fontSize:"12px"}}>{Moment(withdrawal.createdAt).format('YYYY/MM/DD HH:mm')}H - <span className="yellow">Pending</span></p>
												<p className="mb-0" style={{fontSize:"12px"}}>{withdrawal.wallet}</p>
												<p className="mt-2 mb-0" style={{fontSize:"14px"}}>{parseFloat(withdrawal.usdt_amount).toFixed(2)} USDT</p>
												{!withdrawing ? (
													<>
														<CopyToClipboard 
															text={withdrawal.wallet.toLowerCase()}
															onCopy={() => copyAddress()}>
															<button className="btn p-1 mt-2 btn-primary" onClick={(e) => {
															}}>
																{!copied ? (
																	<p className="mb-0" style={{fontSize:"12px"}}>Copy Address</p>
																):(
																	<p className="mb-0" style={{fontSize:"12px"}}>{copyText}</p>
																)}
															</button>
														</CopyToClipboard>
														{blockchain.account === "" || blockchain.usdtContract === null ? (
															<></>
														):(
															<button className="btn p-1 mt-2 ml-2 btn-primary" onClick={(e) => {
																distributeWithdraw(withdrawal);
															}}>
																<p className="mb-0" style={{fontSize:"12px"}}>AutoPay</p>
															</button>
														)}
													</>
												):(
													<>
														<p className="yellow mb-0" style={{fontSize:"12px"}}>
															Processing Withdrawal...
														</p>
													</>
												)}

												<hr className="divider"></hr>
											</div>
										):(
											<>
												<div className="mb-3" key={index}>
													<p className="mb-0" style={{fontSize:"12px"}}>{Moment(withdrawal.createdAt).format('YYYY/MM/DD HH:mm')}H - <span className="profit-green">Completed</span></p>
													<p className="mb-0" style={{fontSize:"12px"}}>{withdrawal.wallet}</p>
													<p className="mt-2 mb-0" style={{fontSize:"14px"}}>{parseFloat(withdrawal.usdt_amount).toFixed(2)} USDT</p>
													<p className="mb-0" style={{fontSize:"12px"}}>
														<a href={withdrawal.txn_link} target="_blank">Blockscan</a>
													</p>
													<hr className="divider"></hr>
												</div>
											</>
										)}
										</>
									))}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>

		</>
  	);

};

export default Withdrawal;