// Dependencies
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';

// Hooks
import { useLogout } from './hooks/useLogout';
import { useAuthContext } from './hooks/useAuthContext';

// Components
import PlatformDataSummary from "./components/PlatformDataSummary";

// Pages
import ManageUsers from "./pages/users";
import Withdrawal from "./pages/withdrawal";
import Admins from "./pages/admins";
import Login from "./pages/login";

import MainLogo from './assets/img/logo-text.png';


function App() {

  const { admin } = useAuthContext();
  
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const { logout } = useLogout();
  const handleLogout = () => {
    logout();
    window.location.href= "/";
  }

  return (
    <Router>

      {admin === null ? (
        <Routes>
          <Route path="/" element={<Login/>}/>
        </Routes>
      ):(
        <>
          {/* Header */}
          <section className="header">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 px-2">
                  <button className="btn" onClick={() => setToggled(!toggled)}>
                    <span className="collapse-btn-icon"><i className="fa-sharp fa-solid fa-bars"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <div className="d-flex">
            <Sidebar
              transitionDuration={600}
              width="260px"
              backgroundColor="#0C0C0C"
              breakPoint="lg"
              onBackdropClick={() => setToggled(false)}
              toggled={toggled}
              rootStyles={{
                height: '100vh',
                zIndex: '110',
                border: 'none'
              }}
            > 
              <NavLink to="/">
                <div className="side-menu-brand-area">
                  <img className="side-menu-logotext" src={MainLogo}></img>
                </div>
              </NavLink>

              <Menu
                renderExpandIcon={({ open }) => 
                  <span className="collapse-icon">
                    {open ? <i className="fa-duotone fa-caret-down mt-2"></i> : <i className="fa-duotone fa-caret-right mt-2"></i>}
                  </span>
                }
                rootStyles={{
                  ['.' + menuClasses.button]: {
                    backgroundColor: '#242935',
                    color: '#DCDCDC',
                    '&:hover': {
                      backgroundColor: '#a68951',
                    },
                  },
                  ['.active']: {
                    backgroundColor: '#a68951',
                  }
                }}
              >
                
                {/* <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-chart-line"></i></span>}
                  component={<NavLink to="/" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Dashboard</p>
                </MenuItem> */}

                <SubMenu
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-users"></i></span>} 
                  label={<p className="side-menu-text">Users</p>}
                > 
                  <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-user-pen"></i></span>}
                    component={<NavLink to="/users" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Manage Users</p>
                  </MenuItem>
                  {/* <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-user-magnifying-glass"></i></span>}
                    component={<NavLink to="/search-user" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Search User</p>
                  </MenuItem> */}
                </SubMenu>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-hand-holding-circle-dollar"></i></span>}
                  component={<NavLink to="/deposit" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Deposit</p>
                </MenuItem>

                <MenuItem 
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-solid fa-money-bill-transfer"></i></span>}
                  component={<NavLink to="/withdrawal" activeClassName={['menu-item'].active}/>}
                > 
                  <p className="side-menu-text">Withdrawal</p>
                </MenuItem>

                <SubMenu
                  icon={<span className="side-menu-icon"><i className="fa-duotone fa-gear"></i></span>} 
                  label={<p className="side-menu-text">System</p>}
                >
                  <MenuItem 
                    icon={<span className="side-menu-icon"><i className="fa-duotone fa-users-gear"></i></span>}
                    component={<NavLink to="/admins" activeClassName={['menu-item'].active}/>}
                  > 
                    <p className="side-menu-text">Admin</p>
                  </MenuItem>
                </SubMenu>

                <MenuItem
                  className="menu-item"
                  icon={<span className="side-menu-icon"><i className="fa-solid fa-right-from-bracket"></i></span>}
                  onClick={handleLogout}
                > 
                  <p className="side-menu-text">Logout</p>
                </MenuItem>

              </Menu>

            </Sidebar>

            <main className="main-content">
              <Routes>
                <Route path="/" element={<PlatformDataSummary/>}/>
                <Route path="/users" element={<ManageUsers/>}/>
                <Route path="/withdrawal" element={<Withdrawal/>}/>
                <Route path="/admins" element={<Admins/>}/>
              </Routes>
            </main>

          </div>
        </>
      )}



    </Router>
  );
}

export default App;